<div *ngIf="data?.response" class="mpr-confirmation-dialog-wrapper">
  <div class="mpr-heading mpr-margin-bottom">{{ popupTitle }}</div>
  <div *ngIf="userWithSucess.length > 0">
    <div
      class="mpr-success-failure-heading mpr-margin-bottom"
      *ngIf="!isPreConfirm"
    >
      {{ successTitle }}
    </div>
    <div>
      <ul *ngFor="let user of userWithSucess">
        <li class="mpr-user-list">
          {{ user.userName }} - {{ user.roleName | replaceRoleName }}
        </li>
      </ul>
    </div>
    <div
      class="mpr-success-failure-heading mpr-margin-bottom"
      *ngIf="isPreConfirm"
    >
      {{ successTitle }}
    </div>
  </div>

  <div *ngIf="disabledUsers.length > 0">
    <div
      class="mpr-success-failure-heading mpr-margin-bottom"
      *ngIf="!isPreConfirm"
    >
      {{ disabledUsersTitle }}
    </div>
    <div>
      <ul *ngFor="let user of disabledUsers">
        <li class="mpr-user-list">
          {{ user.userName }} - {{ user.roleName | replaceRoleName }}
        </li>
      </ul>
    </div>
  </div>

  <div *ngIf="userWithError.length > 0">
    <div class="mpr-success-failure-heading mpr-margin-top mpr-margin-bottom">
      {{ errorTitle }}
    </div>
    <ul *ngFor="let user of userWithError">
      <li class="mpr-user-list">
        {{ user.userName }} - {{ user.roleName | replaceRoleName }}
      </li>
    </ul>
  </div>
  <div
    class="mpr-ok-button-placement mpr-margin-top"
    (click)="navigateBack()"
    *ngIf="!isPreConfirm; else showMprConfirmButtons"
  >
    <button class="mpr-ok-button">OK</button>
  </div>
  <ng-template #showMprConfirmButtons>
    <div class="mpr-confirm-buttons-wrapper">
      <button
        class="mpr-ok-button mpr-cancel-button"
        (click)="closeModal(false)"
      >
        Cancel
      </button>
      <button
        class="mpr-ok-button mpr-confirm-button"
        (click)="closeModal(true)"
      >
        Confirm
      </button>
    </div>
  </ng-template>
</div>
