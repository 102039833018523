import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WORKSPACE_TYPE } from '@core/constants';
import { HeaderParams, MprHttpHeaderModal } from '@core/interfaces';
import { CommonResponseModel } from '@shared/interfaces';
import { ProjectUserList } from 'app/state/project-user/project-user-list-model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CommonWorkspaceService {
  constructor(protected http: HttpClient) {}

  public extendDeprovisionDate(
    userDetails: ProjectUserList,
    requestHeaders: MprHttpHeaderModal,
    workspaceType = WORKSPACE_TYPE.AWS_WORKSPACE
  ): Observable<CommonResponseModel> {
    const headers = new HttpHeaders().append(
      HeaderParams.ROLENAME,
      requestHeaders[HeaderParams.ROLENAME]
    );
    return this.http.put<any>(
      `/user/extend_deprovision`,
      {
        workspaceType,
        userId: userDetails.userId,
        projectId: userDetails.projectId,
      },
      { headers }
    );
  }
}
