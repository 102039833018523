import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { AlertMessageService } from '@core/services';
import { ProjectUserList } from 'app/state/project-user/project-user-list-model';
import { CommonResponseModel, MprConfirmComponent } from '@shared';
import { UserProjectRoleEnum } from '@core';
import { Observable, catchError, throwError, withLatestFrom } from 'rxjs';
import {
  ExtendAWSWorkspaceDeprovisionDate,
  ExtendEC2DeprovisionDate,
  LaunchAWSWorkspaceState,
  LaunchEc2State,
  ResetAWSWorkspaceStatus,
} from 'app/state';
import { MatDialog } from '@angular/material/dialog';
import {
  EventMessageModel,
  HeaderParams,
  MprHttpHeaderModal,
} from '@core/interfaces';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
  EC2_FORCE_DEPROVISION_EXTENSION_DAYS,
  WORKSPACE_TYPE,
  WORKSPACES_FORCE_DEPROVISION_EXTENSION_DAYS,
} from '@core/constants';
import { format2USDate } from '@shared/utils';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'mpr-extend-workspace-deprovision-date',
  templateUrl: './extend-workspace-deprovision-date.component.html',
})
export class ExtendAWSWorkspaceDeprovisionDateComponent
  implements OnInit, OnDestroy
{
  @Input() public data: ProjectUserList = {
    amazonWorkspace: {},
    ec2ForceDeprovisionDate: '',
    ec2Status: '',
    emailId: '',
    firstName: '',
    isPowerUser: false,
    lastName: '',
    machineSize: '',
    projectId: '',
    projectRestrictedDataUseGroups: [],
    roleName: '',
    userId: '',
    userStatus: '',
    powerUserFeatures: {
      isHLBedrockAccess: false,
    },
  };

  @Input() public workspaceType = WORKSPACE_TYPE.AWS_WORKSPACE;

  @Select(LaunchAWSWorkspaceState.getLaunchAws)
  private awsStatus$!: Observable<CommonResponseModel>;

  @Select(LaunchEc2State.getLaunchEc2)
  private ec2Status$!: Observable<CommonResponseModel>;

  @Output() private messageEvent = new EventEmitter<EventMessageModel>();

  public deprovisionDate = '';
  public extendedDate: Date = new Date();

  constructor(
    private store: Store,
    private alertService: AlertMessageService,
    private dialog: MatDialog
  ) {}

  ngOnDestroy(): void {
    this.store.dispatch(new ResetAWSWorkspaceStatus());
  }

  ngOnInit(): void {
    this.setDateForDeprovision();
  }

  public showExtendDeprovisionDateConfirmationPopup(): void {
    const workspaceTypeString =
      this.workspaceType === WORKSPACE_TYPE.AWS_WORKSPACE ? 'workspace' : 'EC2';
    const theExtendedDate = format2USDate(this.extendedDate);
    const dialogRef = this.dialog.open(MprConfirmComponent, {
      data: {
        confirmTitle: 'Extend Retirement Date',
        confirmMessage: `The ${workspaceTypeString} retirement date will be extended to <b>${theExtendedDate}</b>.`,
        confirmData: {
          userId: this.data.userId,
          projectId: this.data.projectId,
          workspaceType: this.workspaceType,
        },
        useEmitterOnConfirmClick: true,
      },
    });

    // Subscribe to the confirmAction emitter in the dialog component
    dialogRef.componentInstance.confirmAction.subscribe((confirmData) => {
      if (!confirmData) return;

      const extendedDateFormattted = `${theExtendedDate.split('/')[2]}-${
        theExtendedDate.split('/')[0]
      }-${theExtendedDate.split('/')[1]}`;
      const messageToParent: EventMessageModel = {
        type: 'extendDeprovisionDate',
        data: { extendedDate: extendedDateFormattted },
      };
      const requestHeaders: MprHttpHeaderModal = {};
      requestHeaders[HeaderParams.ROLENAME] =
        UserProjectRoleEnum.PLATFORM_ADMIN;

      // Incase BE returns an error
      const errorMessageOnApiError = `Unable to extend the retirement date. Please re-try after sometime.`;
      let theDispatchAction = new ExtendAWSWorkspaceDeprovisionDate(
        confirmData.confirmData,
        requestHeaders
      );
      if (this.workspaceType === WORKSPACE_TYPE.EC2_WORKSPACE) {
        theDispatchAction = new ExtendEC2DeprovisionDate(
          confirmData.confirmData,
          requestHeaders
        );
      }

      const latestFrom =
        this.workspaceType === WORKSPACE_TYPE.EC2_WORKSPACE
          ? this.ec2Status$
          : this.awsStatus$;
      this.store
        .dispatch(theDispatchAction)
        .pipe(
          withLatestFrom(latestFrom),
          catchError((err) => {
            this.alertService.error({
              body: errorMessageOnApiError,
            });
            messageToParent.data.status = 'error';
            // Update Parent
            this.messageEvent.emit(messageToParent);
            dialogRef.close();
            return throwError(() => new Error(''));
          })
        )
        .subscribe(([_, response]) => {
          if (response['status_code'] !== 200) {
            messageToParent.data.status = 'error';
            // Update Parent
            this.messageEvent.emit(messageToParent);
            this.alertService.error({
              body: errorMessageOnApiError,
            });
            dialogRef.close();
          } else {
            messageToParent.data.status = 'success';
            // Update Parent
            this.messageEvent.emit(messageToParent);
            this.alertService.success({
              body: `The ${workspaceTypeString} retirement date is extended to <b>${theExtendedDate}</b>`,
              autoDismiss: false,
            });
            this.setDateForDeprovision();
            dialogRef.close();
          }
        });
    });
  }

  private setDateForDeprovision(): void {
    if (
      this.workspaceType === WORKSPACE_TYPE.AWS_WORKSPACE &&
      this.data.amazonWorkspace &&
      this.data.amazonWorkspace?.forceDeprovisionDate
    ) {
      this.deprovisionDate = format2USDate(
        this.data.amazonWorkspace?.forceDeprovisionDate
      );
      this.extendedDate = new Date(this.deprovisionDate);
      this.extendedDate.setDate(
        this.extendedDate.getDate() +
          WORKSPACES_FORCE_DEPROVISION_EXTENSION_DAYS
      );
    } else if (
      this.workspaceType === WORKSPACE_TYPE.EC2_WORKSPACE &&
      this.data.ec2ForceDeprovisionDate
    ) {
      this.deprovisionDate = format2USDate(this.data.ec2ForceDeprovisionDate);
      this.extendedDate = new Date(this.deprovisionDate);
      this.extendedDate.setDate(
        this.extendedDate.getDate() + EC2_FORCE_DEPROVISION_EXTENSION_DAYS
      );
    }
  }
}
