<div fxLayout="row">
  <div class="mpr-button-delete m-l-8">
    Retirement scheduled on {{ deprovisionDate }}
  </div>
  <div class="m-t--1">
    <button
      class="mpr-btn-transparency mpr-cursor-pointer mpr-button-link mpr-underline"
      (click)="showExtendDeprovisionDateConfirmationPopup()"
    >
      Extend
    </button>
  </div>
</div>
