<div>
  <div fxLayout="row">
    <div class="mpr-form-block-field-label">AMAZON WORKSPACE</div>
    @if (data.amazonWorkspace?.forceDeprovisionDate &&
    !this.noExtensionStatus(workspaceStatus)) {
    <mpr-extend-workspace-deprovision-date
      (messageEvent)="receiveMessage($event)"
      [data]="data"
    ></mpr-extend-workspace-deprovision-date>
    }
  </div>
  <div class="d-flex">
    <div
      class="mpr-workpsace-status"
      [ngClass]="displayStatusColor(workspaceStatus)"
    >
      {{ displayWorkspaceStatus(workspaceStatus) }}
    </div>
    <div *ngIf="workspaceStatus === 'TERMINATING'">
      <mat-spinner diameter="20" color="accent"></mat-spinner>
    </div>
  </div>
  <button
    class="mpr-btn-transparency mpr-cursor-pointer mpr-link mpr-terminate-workspace-button"
    *ngIf="displayTerminateWorkspaceButton()"
    (click)="showTerminateAWSConfirmationPopup()"
    [disabled]="isWorkspaceInProgressStatus()"
    [ngClass]="isWorkspaceInProgressStatus() ? 'mpr-disabled' : ''"
  >
    <img
      src="assets/images/delete.svg"
      alt="Delete"
      class="imgClass imgTooltip"
    />
    <span class="mpr-terminate-workspace-button-text">
      Terminate Amazon Workspace
    </span>
  </button>
</div>
