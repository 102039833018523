<div fxLayout="column" class="mpr-mat-dialog-small mpr-not-default-width-dialog">
  <h1 mat-dialog-title>{{ progressInfo.uploadTitle }}</h1>
  <div fxLayout="column" mat-dialog-content *ngIf="!progressInfo.uploadFailed">
    <div
      class="mpr-mat-progressbar-info"
      *ngIf="warningSizeReached && !progressInfo.completedAll"
    >
      Your file upload is in progress and will take some time to complete.
      <span>Please do not close this window.</span>
    </div>

    <div
      fxLayout="row"
      fxLayoutAlign="space-between center"
      *ngIf="progressInfo.noOfUrls > 0"
    >
      {{ progressInfo.noOfUrls }} URL(s) are being cataloged.
    </div>
    <div
      fxLayout="row"
      fxLayoutAlign="space-between center"
      class="mpr-progressbar-text"
    >
      <div>
        {{ progressInfo.completedFiles }} of
        {{ progressInfo.totalFiles }} file(s) uploaded.
        <span *ngIf="progressInfo.failedFiles > 0"
          >{{ progressInfo.failedFiles }} file(s) Failed.</span
        >
      </div>
      <div>{{ progressInfo.totalPercentCompleted | number : '1.0-0' }}%</div>
    </div>
    <div class="mpr-mat-progressbar" fxLayout="row">
      <mat-progress-bar
        [value]="progressInfo.totalPercentCompleted"
      ></mat-progress-bar>
    </div>
  </div>
  <div *ngIf="progressInfo.uploadFailed" mat-dialog-content>
    Upload failed due to technical issues. <br />
    Kindly re-try uploading the files.
  </div>
  <div
    class="mpr-progressbar-actions"
    mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="flex-end center"
    *ngIf="progressInfo.completedAll || progressInfo.uploadFailed"
  >
    <a
      *ngIf="progressInfo.completedAll"
      (click)="proceedToOneTimeJobDetails(data.jobOperationType)"
      mat-dialog-close
      class="mpr-mat-dialog-action-link text-uppercase"
      >View Upload Details</a
    >
    <button
      *ngIf="data.jobOperationType === jobOperationTypeStaging; else uploads"
      mat-button
      class="mpr-button mpr-yellow-button"
      [routerLink]="'/filemanager'"
      mat-dialog-close
    >
      Back To File Manager
    </button>
    <ng-template #uploads>
      <button
        mat-button
        class="mpr-button mpr-yellow-button"
        [routerLink]="'/dashboard'"
        mat-dialog-close
      >
        Go Back Home
      </button>
    </ng-template>
  </div>
</div>
