import * as dayjs from 'dayjs';
import { saveAs } from 'file-saver';

export const getExtension = (filePath: string): string => {
  const ext = filePath.split('.').pop();
  return ext ?? '';
};

/**
 * A function that simply converts the given JSON to the CSV Format
 *
 * @param objArray Array of Objects to be converted to csv
 * @param jsonKeyHeaderLabelMap An object where the Keys are the fields in the csv (in the same order as they are expected in the final) and the values are the headers needed in the csv
 * @returns csv string
 */
export const convertToCSV = (
  objArray: Array<any>,
  jsonKeyHeaderLabelMap: object,
  handleValuesMap?: { [index: string]: any }
): string => {
  const array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
  // eslint-disable-next-line @typescript-eslint/quotes
  let str = '\uFEFF';
  let row = '';
  const headerList = Object.values(jsonKeyHeaderLabelMap);
  const orderArray = Object.keys(jsonKeyHeaderLabelMap);

  // The first row based on the array given
  row = headerList.join(',');
  str += row + '\r\n';

  // Create the rows of the CSV
  for (const rowItem of array) {
    // rowItem is now sorted as per the order of keys in the jsonKeyHeaderLabelMap
    const sortedObject = Object.fromEntries(
      Object.entries(rowItem).sort(
        ([keyA], [keyB]) => orderArray.indexOf(keyA) - orderArray.indexOf(keyB)
      )
    );

    let line = '';
    let fieldIndex = 0;
    // eslint-disable-next-line guard-for-in
    for (const field in sortedObject) {
      // If this is some new field or not present in order mapping object skip it
      if (!orderArray.includes(field)) continue;

      // Add a comma
      if (fieldIndex !== 0) line += ',';

      let fieldVal;
      if (
        handleValuesMap &&
        handleValuesMap.hasOwnProperty(field) &&
        handleValuesMap[field].hasOwnProperty(rowItem[field])
      ) {
        fieldVal = handleValuesMap[field][rowItem[field]];
      } else {
        // If the value also is an array then just join by commas
        fieldVal = !rowItem[field] ? '' : rowItem[field];
      }

      fieldVal = fieldVal instanceof Array ? `${fieldVal.join(',')}` : fieldVal;
      line += escapeCSVValue(fieldVal);
      fieldIndex++;
    }

    str += line + '\r\n';
  }
  return str;
};

export const escapeCSVValue = (value: string): string => {
  if (value == null) return ''; // Handle null or undefined
  value = String(value); // Ensure value is a string
  // Escape double quotes by doubling them
  if (value.includes('"') || value.includes(',') || value.includes('\n')) {
    value = `"${value.replace(/"/g, '""')}"`;
  }
  return value;
};

export const openUrlInIframe = (
  document: Document,
  presignedUrl: string
): void => {
  const downloadFrame = document.createElement('iframe');
  downloadFrame.setAttribute('src', presignedUrl); // Sets the iframe src to presigned url
  downloadFrame.setAttribute('style', 'display:none'); // Hides the Iframe
  document.body.appendChild(downloadFrame); // DOM Manipulation
};

export const saveAsCSV = (stringToExport: string, fileName: string): void => {
  const blob = new Blob([stringToExport], { type: 'text/csv;charset=utf-8' });
  saveAs(blob, `${fileName}`);
};

export const customSort = <T extends { projectId: string }>(arr: T[]): T[] =>
  [...arr].sort((a, b) =>
    a.projectId < b.projectId ? -1 : a.projectId > b.projectId ? 1 : 0
  );

export const getUnixTimestamp = (timestamp?: string): number =>
  dayjs(timestamp).unix();

// Pure JS Version in case we get rid of the dayjs library someday
export const utcDateTimeToUnixTimestamp = (dateTime?: string): number =>
  !dateTime
    ? Math.floor(new Date().getTime() / 1000)
    : Math.floor(new Date(dateTime + ' UTC').getTime() / 1000);

export const format2USDate = (dateString: string | Date, longMonth = false): string => {
  // Date string format : 'YYYY-mm-dd'
  const date =
    dateString instanceof Date
      ? dateString
      : new Date(dateString + 'T00:00:00.000'); // Note: no Z here
  if (isNaN(date.getTime())) {
    // Use getTime() to reliably check for invalid dates
    return 'Invalid date';
  }
  const day: string = String(date.getDate()).padStart(2, '0');
  const year: number = date.getFullYear();
  let month = '';
  let formattedDate = '';
  if(longMonth){
     month = date.toLocaleString('default', { month: 'long' });
     formattedDate = `${month} ${day}, ${year}`;
  } else {
     month = String(date.getMonth() + 1).padStart(2, '0');
     formattedDate = `${month}/${day}/${year}`
  }
  return formattedDate;
};
