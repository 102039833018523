<div
  class="mpr-mat-dialog-confirm mpr-mat-dialog-small mpr-not-default-width-dialog"
>
  <div fxLayout="row" fxLayoutAlign="space-between">
    <h1 mat-dialog-title id="mpr-r-title">Select R Version</h1>
    <div class="mpr-r-close m-16" (click)="closeDialog()">
      <img src="assets/images/cancel-popup.svg" alt="Close icon" />
    </div>
  </div>
  <div mat-dialog-content>
    <div class="mpr-margin-bottom">
      Select the R version you would like to use from R Studio
    </div>
    <mat-form-field
      class="mpr-mat-select-field mpr-dropdown-width"
      [hideRequiredMarker]="true"
      appearance="outline"
    >
      <mat-select
        id="SelectRVersion"
        class="mpr-form-block-field-input"
        matInput
        placeholder="Select R version"
        #format="ngModel"
        panelClass="mpr-select-option-panel mpr-mat-arrow"
        [(ngModel)]="selectedVersion"
      >
        <mat-option
          *ngFor="let version of displayRversionValues"
          [value]="version"
          >{{ version }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </div>
  <div mat-dialog-actions class="mpr-progressbar-actions">
    <div>
      <button
        mat-stroked-button
        class="mpr-button mpr-blue-button mpr-cancel-button"
        (click)="closeDialog()"
      >
        Cancel
      </button>
    </div>
    <div>
      <button
        mat-button
        class="mpr-button mpr-yellow-button"
        id="mpr-proceed"
        [disabled]="!selectedVersion || disableProceed"
        (click)="updateRVersion(selectedVersion)"
      >
        Proceed
      </button>
    </div>
  </div>
</div>
