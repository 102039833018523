<div fxLayout="column" class="mpr-mat-dialog-small mpr-not-default-width-dialog">
  <h1 mat-dialog-title>{{ title }}</h1>
  <div mat-dialog-content>
    {{ description }}
  </div>
  <div
    class="mpr-progressbar-actions"
    mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="flex-end center"
    *ngIf="!uploadPending"
  >
    <a
      *ngIf="uploadSuccessful"
      (click)="proceedToOneTimeJobDetails(data.jobOperationType)"
      mat-dialog-close
      class="mpr-mat-dialog-action-link text-uppercase"
      >View Upload Details</a
    >

    <button
      *ngIf="data.jobOperationType === jobOperationType; else uploads"
      mat-button
      class="mpr-button mpr-yellow-button"
      [routerLink]="'/filemanager'"
      mat-dialog-close
    >
      Back To File Manager
    </button>
    <ng-template #uploads>
      <button
        mat-button
        class="mpr-button mpr-yellow-button"
        [routerLink]="'/dashboard'"
        mat-dialog-close
      >
        Go Back Home
      </button>
    </ng-template>
  </div>
</div>
