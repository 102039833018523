import { UserAWSStatus } from '@theme/interfaces/user-aws-status';
import { PowerUserFeatures } from './power-user-features-model';
export interface ProjectUserList {
  amazonWorkspace?: UserAWSStatus;
  ec2ForceDeprovisionDate?: string;
  ec2Status: string;
  emailId: string;
  firstName: string;
  isPowerUser: boolean;
  lastName: string;
  machineSize: string;
  powerUserFeatures: PowerUserFeatures;
  projectId: string;
  projectLevelUserStatus?: string;
  projectRestrictedDataUseGroups: string[];
  reason?: string;
  roleName: string;
  userId: string;
  userStatus: string;
}
