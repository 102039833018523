<div class="mpr-mat-dialog-small mpr-not-default-width-dialog">
  <h1 mat-dialog-title class="mpr-dialog-title-workspace-update">
    <span class="mpr-dialog-title-with-close">Update Amazon Workspace</span>
    <mat-icon mat-dialog-close class="mpr-close-icon">close</mat-icon>
  </h1>
  <div mat-dialog-content>
    <div class="mpr-context">
      Newer version(s) of the Amazon WorkSpace are now available. Updating to
      the latest version provides the latest features, security updates, and bug
      fixes.
    </div>
    <div class="mpr-context mpr-force-deprov-date-section">
      <span>
        <img
          class="mpr-red-warning-icon"
          src="assets/images/warning-icon-red-triangle.svg"
          alt="Workspace force deprovisioning warning"
        />
      </span>
      <span>
        If you don't update by {{ formattedForceDeprovDate }}, your current
        workspace will be automatically retired.
      </span>
    </div>
    <div class="mpr-select">
      <div>
        Please select a workspace type (this will be used across all of your
        projects):
      </div>
      <div>
        <mat-radio-group
          aria-labelledby="mpr-radio-group-label"
          [(ngModel)]="updatedWorkspaceType"
        >
          <mat-radio-button
            class="mpr-radio"
            *ngFor="let updateType of availableWSTypes"
            [value]="updateType"
          >
            {{ updateType | replaceRoleName }}
            <span
              *ngIf="
                updateType.toLowerCase().includes('researcher');
                else developer
              "
            >
              (Includes tools like Excel, WinMerge, Notepad++, Stata, VS Code)
            </span>
            <ng-template #developer>
              (Provides extra processing power, May incur additional cost for
              your project)
            </ng-template>
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <div class="mpr-info">
      <b>Note:</b> By choosing to update, your current Amazon Workspace will be
      terminated.
      <div class="m-t-12">
        To avoid losing the work made locally in the current workspace for all
        of your projects, please move all data to the project's staging location
        and push code to GitHub. Once the workspace is updated, pull code and
        data from the respective GitHub and staging locations of your projects
        to continue working as before.
      </div>
      <div class="m-t-12">
        Would you like to update your Amazon WorkSpace now?
      </div>
    </div>
  </div>
  <div mat-dialog-actions class="mpr-button-enable">
    <button mat-button mat-dialog-close class="mpr-button mpr-cancel-button">
      Dismiss
    </button>
    <button
      mat-button
      class="mpr-button mpr-yellow-button"
      [ngClass]="disableAction ? 'mpr-disabled' : ''"
      (click)="onUpdateSubmit()"
    >
      Update
    </button>
  </div>
</div>
