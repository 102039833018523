/** NGXS recommends we keep related actions in a single file hence below Linting exception is added.
/*  See : https://www.ngxs.io/concepts/actions#group-your-actions */
/* eslint-disable max-classes-per-file */

import { MprHttpHeaderModal } from '@core/interfaces';
import { ProjectUserList } from '../project-user/project-user-list-model';

export class ExtendAWSWorkspaceDeprovisionDate {
  public static type =
    '[DeprovisionDateExtensionAWSWorkspace] workspace deprovision date extension';
  constructor(
    public userProjectDetails: ProjectUserList,
    public requestHeaders: MprHttpHeaderModal
  ) {}
}

export class LaunchAWSWorkspace {
  public static type = '[LaunchAwsWorkspace] workspace data ';
  constructor(public action = 'start') {}
}

export class ProvisionAWSWorkspace {
  public static type = '[ProvisionWorkspace] workspace data ';
  constructor(public action = 'provision', public awsPassword?: string) {}
}

export class ReprovisionAWSWorkspace {
  public static type = '[ReprovisionWorkspace] workspace data ';
  constructor(public updatedWorkspaceType: string) {}
}

export class StopAWSWorkspace {
  public static type = '[StopWorkspace] workspace data ';
  constructor(public action = 'stop') {}
}

export class TerminateUserAWSWorkspace {
  public static type = '[TerminateUserAWSWorkspace] workspace data ';
  constructor(
    public userProjectDetails: ProjectUserList,
    public requestHeaders: MprHttpHeaderModal
  ) {}
}
export class ForgotAWSPassword {
  public static type = '[Forgot] aws workspace password ';
  constructor(public password: string, public workspaceUserName: string) {}
}

export class ResetAWSWorkspaceStatus {
  public static type = '[Reset] aws workspace';
}
