import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { CreateProjectFromControls } from '@core';
import { RESTRICTED_GROUP_REGEX } from '@core/constants';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import {
  mprMatChipA11yDirective,
  noSpaceInBetweenStringValidator,
  noWhitespaceValidator,
} from '@shared';
import {
  FeatureFlagsState,
  formValue,
  GlobalAttributesModel,
  GlobalAttributesValueModel,
  projectDetails,
} from 'app/state';
import { FeatureFlagsStateModel } from 'app/state/feature-flags';
import { AddRestrictedGroupComponent } from '../add-restricted-group/add-restricted-group.component';
@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'mpr-project-form',
  templateUrl: './project-form.component.html',
  styleUrls: ['./project-form.component.scss'],
})
export class ProjectFormComponent implements OnInit {
  @Input() public data: projectDetails = {
    credit: 0,
    dwAccess: false,
    hasPowerUsers: false,
    externalProjectAccountsEnabled: false,
    projectAttributes: [],
    projectName: '',
    projectNickname: '',
    projectNumber: '',
    projectRestrictedDataUseGroups: [],
    projectSize: '',
    rdsEnabled: false,
  };
  @Input() public globalAttributes: GlobalAttributesModel[] = [];
  @Output() public proceedToShowCancelPopup = new EventEmitter<string>();
  @Output() public proceedToShowPopup = new EventEmitter<any>();
  @ViewChild('restrictedGroupInput')
  public restrictedGroupInput!: ElementRef<HTMLInputElement>;
  @Input() public roleName = '';
  @ViewChild('typeOfProjectSize') public skipper!: MatSelect;
  public addOnBlur = true;
  public afterChangeFormValue: string[] = [];
  public changedFormValue: formValue = {};
  public isAnyChangeInEditForm = true;
  public isEdit = false;
  public numberValidations = '^[a-zA-Z0-9-_. ]+$';
  public orginalFormValue: projectDetails = {
    credit: 0,
    dwAccess: false,
    hasPowerUsers: false,
    externalProjectAccountsEnabled: false,
    projectAttributes: [],
    projectName: '',
    projectNickname: '',
    projectNumber: '',
    projectRestrictedDataUseGroups: [],
    projectSize: '',
    rdsEnabled: false,
  };
  public projectForm: FormGroup;
  public restrictedDataUseGroups: Array<string> = [];
  public selectedRestrictedGroup: Array<string> = [];
  public readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  public specialValidators = '^[a-zA-Z0-9-_.]+$';
  public wareHouseSize = [
    'X-Small',
    'Small',
    'Medium',
    'Large',
    'X-Large',
    '2X-Large',
    '3X-Large',
    '4X-Large',
    '5X-Large',
    '6X-Large',
  ];

  constructor(
    public store: Store,
    private fb: FormBuilder,
    private dialog: MatDialog,
  ) {
    this.numberValidations = '^[1-9]d*$';

    this.projectForm = this.fb.group({
      projectNumber: [
        '',
        [
          Validators.required,
          noWhitespaceValidator(),
          Validators.pattern(this.specialValidators),
          Validators.maxLength(12),
        ],
      ],
      projectNickname: [
        '',
        [
          Validators.required,
          noWhitespaceValidator(),
          Validators.pattern(this.specialValidators),
          Validators.maxLength(12),
        ],
      ],
      projectName: [
        '',
        [
          Validators.required,
          noWhitespaceValidator(),
          Validators.maxLength(100),
        ],
      ],
      projectRestrictedDataUseGroups: [[]],
      dwAccess: [false],
      hasPowerUsers: [false],
      externalProjectAccountsEnabled: [false],
      rdsEnabled: [false],
      projectSize: ['', [Validators.required]],
      credit: [
        '',
        [Validators.required, Validators.pattern(this.numberValidations)],
      ],
    });
  }

  public addChip(event: MatChipInputEvent): void {
    const formControl = this.projectForm.controls;
    const checkValidatorReturns = this.customTextInputValidator(
      formControl[CreateProjectFromControls.PROJECT_RESTRICTED_DATA_USE_GROUP],
    );
    if (
      !formControl[CreateProjectFromControls.PROJECT_RESTRICTED_DATA_USE_GROUP]
        .errors
    ) {
      const value = (event.value || '').trim();
      if (value.length <= 20) {
        if (value && !checkValidatorReturns) {
          this.restrictedDataUseGroups.push(value);
        }
        // Clear the input value
        event.chipInput?.clear();
        formControl[
          CreateProjectFromControls.PROJECT_RESTRICTED_DATA_USE_GROUP
        ].setValue(this.restrictedDataUseGroups);
      }
    }
  }

  public addRestrictedGroup(): void {
    const dialogRef = this.dialog
      .open(AddRestrictedGroupComponent, {
        disableClose: true,
      })
      .afterClosed()
      .subscribe((dialogResponse) => {
        if (dialogResponse.result === 'success') {
          this.restrictedDataUseGroups.push(
            dialogResponse.restrictedGroupValue,
          );
          this.projectForm.patchValue({
            projectRestrictedDataUseGroups: this.restrictedDataUseGroups,
          });
        }
      });
  }

  public changeSelection(event: MatAutocompleteSelectedEvent): void {
    if (this.selectedRestrictedGroup.indexOf(event.option.viewValue) >= 0) {
      this.removeChip(event.option.viewValue);
    } else {
      this.selectedChip(event);
    }
  }

  public customTextInputValidator(
    control: AbstractControl,
  ): { [key: string]: any } | null | [[key: string]] {
    let valid = true;
    let invalidText;
    const invalidTexts = ['staging', 'restricted'];
    const inputValue = control.value.toString();
    if (inputValue) {
      if (invalidTexts.includes(inputValue.toLowerCase())) {
        valid = false;
        invalidText = inputValue;
      }
    }
    return valid
      ? null
      : {
          textValidators: true,
          message: `Restricted data use groups cannot be named as "staging" or "restricted"`,
        };
  }

  public customWhiteSpaceValidator(
    control: AbstractControl,
    editRestrictedGroup?: boolean,
  ): { [key: string]: any } | null | [[key: string]] {
    const spacePattern = new RegExp(RESTRICTED_GROUP_REGEX);
    let valid;
    if (typeof control.value === 'object' && control.value.length > 0) {
      valid = control.value.filter((res: string) => {
        spacePattern.test(res);
      });
    } else if (typeof control.value === 'object' && editRestrictedGroup) {
      valid = spacePattern.test(control.value);
    } else if (
      typeof control.value === 'object' &&
      control.value.length === 0
    ) {
      valid = true;
    } else {
      valid = spacePattern.test(control.value);
    }
    return valid ? null : { spaceValidators: true };
  }

  public disableControl(control: AbstractControl): void {
    control.setValue('');
    control.disable();
    control.clearValidators();
    control.updateValueAndValidity();
  }
  public enableControl(formField: string): void {
    const control = this.projectForm.controls[formField];
    control.enable();
    control.setValidators([Validators.required]);
    control.updateValueAndValidity();
  }

  public enableDataWareHouse(): void {
    const wareHouseSizeControl =
      this.projectForm.controls[CreateProjectFromControls.WARE_HOUSE_SIZE];
    const creditControl =
      this.projectForm.controls[CreateProjectFromControls.CREDIT];
    if (this.projectForm.controls[CreateProjectFromControls.DWACCESS].value) {
      this.enableControl(CreateProjectFromControls.WARE_HOUSE_SIZE);
      this.enableControl(CreateProjectFromControls.CREDIT);
    } else {
      this.disableControl(wareHouseSizeControl);
      this.disableControl(creditControl);
    }
  }

  public getAttributeFormControlValueLength(
    attribute: GlobalAttributesModel,
  ): number {
    return this.projectForm.get(attribute.attributeId)?.value.length;
  }

  public getProjectIdValue(): string {
    if (
      this.projectForm.controls[CreateProjectFromControls.PROJECT_NUMBER]
        .value ||
      this.projectForm.controls[CreateProjectFromControls.PROJECT_NICK_NAME]
        .value
    ) {
      const formattedProjectNumebr =
        this.projectForm.controls[
          CreateProjectFromControls.PROJECT_NUMBER
        ].value.trim();
      const formattedProjectNickName =
        this.projectForm.controls[
          CreateProjectFromControls.PROJECT_NICK_NAME
        ].value.trim();
      return `${formattedProjectNumebr}_${formattedProjectNickName}`;
    } else {
      return ``;
    }
  }

  public handleA11yForMatChip(matChipElementName: string): void {
    const mprMatChipA11y = new mprMatChipA11yDirective(
      this.restrictedGroupInput,
    );
    mprMatChipA11y.setRoleA11yAttributesForId(matChipElementName);
  }

  public isExternalProjectAccounts(): boolean {
    return (
      this.store
        .selectSnapshot(FeatureFlagsState.returnFeatureFlags)
        .externalProjectAccountsFeatureFlag.toLowerCase() === 'true'
    );
  }

  public isRDSEnabled(): boolean {
    const featureFlags: FeatureFlagsStateModel = this.store.selectSnapshot(FeatureFlagsState.returnFeatureFlags);
    return (
      featureFlags.rdsFeatureFlag.toLowerCase() === 'true' && 
      featureFlags.serviceAccountFeatureFlag.toLowerCase() === 'true'
    );
  }

  ngOnInit(): void {
    if (this.globalAttributes.length > 0) {
      this.globalAttributes.forEach((attribute) => {
        this.projectForm.addControl(
          attribute.attributeId,
          new FormControl('', [
            Validators.maxLength(30),
            noSpaceInBetweenStringValidator(),
          ]),
        );
      });
    }

    if (this.data && this.data.projectName) {
      this.isEdit = true;
      this.projectForm.patchValue({
        projectNumber: this.data.projectNumber,
        projectNickname: this.data.projectNickname,
        projectName: this.data.projectName,
        projectRestrictedDataUseGroups:
          this.data.projectRestrictedDataUseGroups,
        dwAccess: this.data.dwAccess,
        hasPowerUsers: this.data.hasPowerUsers ? true : false,
        externalProjectAccountsEnabled: this.data.externalProjectAccountsEnabled ? true : false,
        rdsEnabled: this.data.rdsEnabled ? true : false,
        wareHouseSize: this.data.projectSize ? this.data.projectSize : '',
        credit: this.data.credit ? this.data.credit : '',
      });
      if(this.data.externalProjectAccountsEnabled) {
        this.projectForm.get('externalProjectAccountsEnabled')?.disable()
      }
      if(this.data.rdsEnabled) {
        this.projectForm.get('rdsEnabled')?.disable()
      }
      if(this.data.dwAccess) {
        this.projectForm.get('dwAccess')?.disable()
      }
      if(this.data.hasPowerUsers) {
        this.projectForm.get('hasPowerUsers')?.disable()
      }

      if (
        this.data.projectAttributes &&
        this.data.projectAttributes.length > 0
      ) {
        this.globalAttributes.forEach((attribute) => {
          if (this.data.projectAttributes) {
            const projectAttribute = this.data.projectAttributes.find(
              (projectAttr) =>
                projectAttr.attributeId === attribute.attributeId,
            );
            if (projectAttribute) {
              this.projectForm.controls[attribute.attributeId].setValue(
                projectAttribute.attributeValue,
              );
            }
          }
        });
      }

      if (
        this.data.projectRestrictedDataUseGroups &&
        this.data.projectRestrictedDataUseGroups?.length > 0
      ) {
        this.restrictedDataUseGroups = [
          ...this.data.projectRestrictedDataUseGroups,
        ];
      }

      if (this.data.projectSize) {
        this.projectForm.controls[
          CreateProjectFromControls.WARE_HOUSE_SIZE
        ].setValue(this.data.projectSize);
      }
      this.projectForm.controls[
        CreateProjectFromControls.PROJECT_NUMBER
      ].disable();
      this.projectForm.controls[
        CreateProjectFromControls.PROJECT_NICK_NAME
      ].disable();
      this.enableDataWareHouse();
      const initialValue = this.projectForm.value;
      this.projectForm.valueChanges.subscribe((selectedValue) => {
        this.afterChangeFormValue = [];
        this.afterChangeFormValue = Object.keys(initialValue).filter(
          (key) => initialValue[key] !== selectedValue[key],
        );

        if (this.afterChangeFormValue.includes('dwAccess')) {
          this.afterChangeFormValue.push('credit', 'projectSize');
        }

        this.changedFormValue = { ...this.changedFormValue };
        // eslint-disable-next-line guard-for-in
        for (const i in this.afterChangeFormValue) {
          this.changedFormValue[this.afterChangeFormValue[i]] =
            selectedValue[this.afterChangeFormValue[i]];
        }
        this.isAnyChangeInEditForm = false;
      });
    } else {
      this.isEdit = false;
      this.isAnyChangeInEditForm = false;
      const wareHouseSizeControl =
        this.projectForm.controls[CreateProjectFromControls.WARE_HOUSE_SIZE];
      const creditControl =
        this.projectForm.controls[CreateProjectFromControls.CREDIT];
      this.disableControl(creditControl);
      this.disableControl(wareHouseSizeControl);
      this.projectForm.controls[
        CreateProjectFromControls.PROJECT_RESTRICTED_DATA_USE_GROUP
      ].setValidators([
        Validators.maxLength(20),
        this.customWhiteSpaceValidator,
        this.customTextInputValidator,
      ]);
    }
  }

  public removeChip(chip: string): void {
    const index = this.restrictedDataUseGroups.indexOf(chip);

    if (index >= 0) {
      this.restrictedDataUseGroups.splice(index, 1);
    }
    // For 508 we need this: on remove the attributes applied get reset so we need to re-apply the directive.
    if (this.restrictedDataUseGroups.length === 0) {
      this.handleA11yForMatChip('restrictedDataUseGroups');
    }
  }

  public selectedChip(event: MatAutocompleteSelectedEvent): void {
    this.restrictedDataUseGroups.push(event.option.viewValue);
    this.restrictedGroupInput.nativeElement.value = '';
    this.projectForm.controls[
      CreateProjectFromControls.PROJECT_RESTRICTED_DATA_USE_GROUP
    ].setValue(this.restrictedDataUseGroups);
  }

  public showCancelPopup(): void {
    this.proceedToShowCancelPopup.emit();
  }

  public showConfirmPopup(): void {
    if (this.isEdit) {
      const projectFormValue = this.projectForm.value;
      const changedFormValue = { ...this.changedFormValue };
      const projectAttributes: GlobalAttributesValueModel[] = [];
      this.globalAttributes.forEach((attribute) => {
        const attributeId = attribute.attributeId;
        const currentValue = this.projectForm.get(attributeId)?.value;
        if (changedFormValue.hasOwnProperty(attributeId)) {
          projectAttributes.push({
            attributeId,
            attributeValue: currentValue,
          });
          // Remove the attributeId key from changedFormValue as it's now in projectAttributes
          delete changedFormValue[attributeId];
        }
      });
      if (projectAttributes.length > 0) {
        changedFormValue['projectAttributes'] = projectAttributes;
      }
      this.proceedToShowPopup.emit({ projectFormValue, changedFormValue });
    } else {
      const projectFormValue = this.projectForm.value;
      const projectAttributes: GlobalAttributesValueModel[] = [];
      if (this.globalAttributes.length > 0) {
        this.globalAttributes.forEach((attribute) => {
          if (this.projectForm.get(attribute.attributeId)?.value) {
            projectAttributes.push({
              attributeId: attribute.attributeId,
              attributeValue: this.projectForm.get(attribute.attributeId)
                ?.value,
            });
          }
        });
      }
      projectFormValue['projectAttributes'] = projectAttributes;

      this.proceedToShowPopup.emit(projectFormValue);
    }
  }

  public validationErrorForAttributes(
    control: any,
    attribute: GlobalAttributesModel,
  ): boolean {
    return (
      this.projectForm.controls[attribute.attributeId].touched &&
      this.projectForm.controls[attribute.attributeId].errors?.[
        'spaceInBetween'
      ]
    );
  }
}
