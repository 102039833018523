import { Clipboard } from '@angular/cdk/clipboard';
import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { WorkspaceStatus } from '@core/enums';
import { AlertMessageService } from '@core/services';
import { environment } from '@env/environment';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { CommonResponseModel, WorkspacesInputsModel } from '@shared';
import { SkipMainContentService } from '@shared/services';
import { User, UserAWSStatus } from '@theme/interfaces';
import {
  ApiInProgressState,
  FeatureFlagsState,
  LaunchAWSWorkspace,
  LaunchAWSWorkspaceState,
  LoadUserdata,
  LoggedinUserState,
  ProjectState,
  ProvisionAWSWorkspace,
  ResetAWSWorkspaceStatus,
  ResetUserdata,
  StopAWSWorkspace,
  UserProjectState,
  UserState,
} from 'app/state';
import { FeatureFlagsStateModel } from 'app/state/feature-flags';
import { format2USDate } from '@shared/utils';
import ProjectModel from 'app/state/project/project-state-model';
import { catchError, Observable, Subscription, throwError } from 'rxjs';
import { SetAwsWorkspacePasswordComponent } from '../set-aws-workspace-password/set-aws-workspace-password.component';
import { WorkspaceUpdateComponent } from '../workspace-update/workspace-update.component';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-aws-workspace',
  templateUrl: './aws-workspace.component.html',
  styleUrls: ['./aws-workspace.component.scss'],
})
export class AwsWorkspaceComponent implements OnInit, OnChanges, OnDestroy {
  // 'GetUser' should same as the value in ApiInprogressStateModel state defaults
  @Select(ApiInProgressState.getApiInProgressStateAction('GetUser'))
  public fetchUserApiInProgress$?: Observable<boolean>;
  @Input() public inputs!: WorkspacesInputsModel;
  @Select(ProjectState.getSelectedProjectData)
  public selectedProject$!: Observable<ProjectModel>;
  @Input() public selectedTabIndex!: number;
  @Select(LaunchAWSWorkspaceState.getLaunchAws)
  private awsStatus$?: Observable<CommonResponseModel>;
  @Select(UserState.getUsers)
  private user$?: Observable<User>;
  public awsURL = '';
  public awsWorkspaceUserGuideHelpURL = `${environment.helpPageNowUrl}amazon-workspace-user-guide/accessing-the-workspace`;
  public disableStopButton = false;
  public formattedForceDeprovDate = '';
  public isRefresh = false;
  public showConnectingToRDSLink = false;
  public showSpinner = true;
  public workspaceInfo: UserAWSStatus = {};
  public workspaceStatus: string = WorkspaceStatus.STOPPED;
  public workspaceStatusEnum = WorkspaceStatus;
  private isUpdateAvailable = false;
  private updateCheckDone = false;
  private userSubscription$: Subscription | undefined;
  private workspaceActionInProgress: string[] = [
    WorkspaceStatus.PENDING,
    WorkspaceStatus.STARTING,
    WorkspaceStatus.STOPPING,
    WorkspaceStatus.TERMINATING,
    WorkspaceStatus.REPROVISIONING,
  ];

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @ViewChild('skipper') public skipper!: MatButton;

  constructor(
    private clipboard: Clipboard,
    private skipMainContentService: SkipMainContentService,
    private router: Router,
    public store: Store,
    public alertService: AlertMessageService,
    public dialog: MatDialog
  ) {}

  public checkIfWorkspaceActionInProgress(): boolean {
    return this.workspaceActionInProgress.includes(this.workspaceStatus);
  }

  public copyToClipBoard(copyText: string): void {
    this.clipboard.copy(copyText);
  }

  public displayQuickLinks(workspaceStatus: any): boolean {
    return (
      this.showConnectingToRDSLink ||
      (workspaceStatus !== this.workspaceStatusEnum.NOT_PROVISIONED &&
        workspaceStatus !== this.workspaceStatusEnum.PENDING &&
        workspaceStatus !== this.workspaceStatusEnum.FAILED)
    );
  }

  public doRefresh(): void {
    this.isRefresh = false;
    this.store.dispatch(new LoadUserdata());
  }

  public launchAWSWorkspace(): void {
    this.isRefresh = true;
    this.workspaceStatus = this.workspaceStatusEnum.STARTING;
    this.store.dispatch(new LaunchAWSWorkspace()).pipe(
      catchError((err) => {
        this.isRefresh = false;
        this.workspaceStatus = this.workspaceStatusEnum.STOPPED;
        this.alertService.error({
          body: err.error.message,
        });
        return throwError(() => new Error(''));
      })
    );
  }

  public navigateConnectingToRDS(): void {
    this.router.navigate(['/rds/view-rds-from-workspace']);
  }

  public navigateToWorkspace(): void {
    window.open(`${this.awsURL}`, '_blank');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedTabIndex'].currentValue !== 1 || this.updateCheckDone)
      return;

    if (this.showUpdateWorkspaceSectionOrPopup()) this.showUpdatePopup();
  }

  ngOnDestroy(): void {
    this.store.dispatch(new ResetAWSWorkspaceStatus());
  }

  ngOnInit(): void {
    let popupShown = false;
    this.store.dispatch(new ResetUserdata());
    this.store.dispatch(new LoadUserdata());
    this.userSubscription$ = this.user$?.subscribe((user: User) => {
      if (user.userId !== '') this.showSpinner = false;
      this.workspaceStatus =
        user.amazonWorkspace && user.amazonWorkspace.workspaceStatus
          ? user.amazonWorkspace.workspaceStatus
          : this.workspaceStatusEnum.NOT_PROVISIONED;
      this.isUpdateAvailable =
        user.amazonWorkspace?.isNewVersionAvailable ?? false;
      this.workspaceInfo = user.amazonWorkspace ? user.amazonWorkspace : {};
      this.isRefresh =
        this.workspaceStatus === this.workspaceStatusEnum.STARTING ||
        this.workspaceStatus === this.workspaceStatusEnum.STOPPING ||
        this.workspaceStatus === this.workspaceStatusEnum.TERMINATING;
      this.awsURL =
        user.amazonWorkspace && user.amazonWorkspace.url
          ? user.amazonWorkspace.url
          : '';
      const deprovDate =
        user.amazonWorkspace && user.amazonWorkspace.forceDeprovisionDate
          ? user.amazonWorkspace.forceDeprovisionDate
          : '';
      this.formattedForceDeprovDate = format2USDate(deprovDate, true);

      const roleName = this.store.selectSnapshot(
        UserProjectState.getSelectedProjectRole
      );
      if (
        user.userId &&
        !popupShown &&
        roleName.toLowerCase().includes('researcher') &&
        this.showUpdateWorkspaceSectionOrPopup()
      ) {
        popupShown = true;
        this.updateCheckDone = true;
        this.showUpdatePopup();
      }
    });
    this.skipMainContentService.skip$.subscribe((res) => {
      this.skipper.focus();
    });

    this.awsStatus$?.subscribe((awsResponse: CommonResponseModel) => {
      if (awsResponse.status_code === 200) {
        if (awsResponse.message.includes('Initiated Server Provisioning')) {
          this.workspaceStatus = this.workspaceStatusEnum.PENDING;
        } else if (
          awsResponse.message.includes('Initiated Server Reprovisioning')
        ) {
          this.workspaceStatus = this.workspaceStatusEnum.REPROVISIONING;
        } else if (awsResponse.message.toLowerCase().includes('is starting')) {
          this.workspaceStatus = this.workspaceStatusEnum.STARTING;
        } else if (awsResponse.message.toLowerCase().includes('is stopping')) {
          this.workspaceStatus = this.workspaceStatusEnum.STOPPING;
        }
      }
      this.selectedProject$.subscribe((project: ProjectModel) => {
        this.showConnectingToRDSLink =
          project.rdsEnabled && project.serviceAccountEnabled;
      });
      if (awsResponse.status_code !== 200 && awsResponse.status_code !== 0) {
        this.alertService.error({
          body: awsResponse.message,
          autoDismiss: true,
        });
      }
    });
    // Internal check not done in this case hence use state value
    const noInputs = !this.inputs;

    if (noInputs) {
      const featureFlags = this.store.selectSnapshot(
        FeatureFlagsState.returnFeatureFlags
      );
      const isUserInternal = this.store.selectSnapshot(
        LoggedinUserState.getIsUserInternal
      );
      const commonSupportLink = isUserInternal
        ? featureFlags.internalContactUsUrl
        : featureFlags.externalContactUsUrl;
      const selectedTab = 1;
      this.inputs = {
        commonSupportLink,
        isUserInternal,
        selectedTab,
      };
    }
  }

  public provisionAWSWorkspace(): void {
    this.store.dispatch(new ProvisionAWSWorkspace());
  }

  public setAWSWorkspacePassword(forgotPassword: boolean): void {
    this.dialog.open(SetAwsWorkspacePasswordComponent, {
      disableClose: false,
      data: {
        isForgotPassword: forgotPassword,
        userId: this.workspaceInfo?.userName,
      },
    });
  }

  public showUpdatePopup(): void {
    if (!this.checkIfWorkspaceActionInProgress()) {
      const dialogRef = this.dialog.open(WorkspaceUpdateComponent, {
        disableClose: false,
        data: { ...this.inputs },
        height: '96vh'
      });

      // NOTE: You do not need to unsubscribe from a dialogRef.afterClosed
      dialogRef.afterClosed().subscribe((data): void => {
        if (data && data.result && data.result === 'success') {
          this.alertService.success({
            body: `Your workspace is being updated.`,
          });
          this.workspaceStatus = WorkspaceStatus.REPROVISIONING;
        }
      });
    }
  }

  public showUpdateWorkspaceSectionOrPopup(
    isCheckFromTemplate = false
  ): boolean {
    if (!isCheckFromTemplate) this.updateCheckDone = true;
    if (
      !this.isUpdateAvailable ||
      this.workspaceStatus === WorkspaceStatus.REPROVISIONING ||
      this.workspaceStatus === WorkspaceStatus.NOT_PROVISIONED ||
      this.workspaceStatus === WorkspaceStatus.TERMINATING ||
      this.workspaceStatus === WorkspaceStatus.FAILED ||
      this.workspaceStatus === WorkspaceStatus.PENDING ||
      this.workspaceStatus === WorkspaceStatus.REPROVISIONING_FAILED
    ) {
      return false;
    }

    return true;
  }

  public stopAWSWorkspace(): void {
    this.isRefresh = true;
    this.workspaceStatus = this.workspaceStatusEnum.STOPPING;
    this.store.dispatch(new StopAWSWorkspace()).pipe(
      catchError((err) => {
        this.isRefresh = false;
        this.workspaceStatus = this.workspaceStatusEnum.AVAILABLE;
        this.alertService.error({
          body: err.error.message,
        });
        return throwError(() => new Error(''));
      })
    );
  }
}
